import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function App() {
  useEffect(() => {
    // Update the document title using the browser API
  });

  return (
    <Router>
      <header className="App-header">
        <img src="/logo.png" className="logoInmobiliaria" />
      </header>
      <Switch>
        <Route exact path="/">
          <Redirect />
        </Route>
        <Route path="/:id" children={<Property />} />
      </Switch>

      <footer className="App-footer">
        <p>&copy; 2021 Proper</p>
        {/* <a
          className="App-link"
          href="https://propr.mx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conoce Proper
        </a> */}
      </footer>
    </Router>
  );
}

function Property() {
  let { id } = useParams();
  const [detalles, setDetalles] = useState(false);
  const [photos, setPhotos] = useState(false);

  var foundProperty = false;
  var propertyId = codes.find(function (code, index) {
    if (code.code == id && code.property > 0 && code.active) {
      return code.property;
    }
  });
  if (propertyId) {
    foundProperty = properties.find(function (property, index) {
      console.log("comparison", {
        searchedId: property.id,
        propertyId,
        foundProperty: property.id == propertyId,
      });
      if (property.id == propertyId.property) {
        return property;
      }
    });
  }
  console.log("codes", { id, propertyId, foundProperty });
  if (foundProperty) {
    const agency = "5piso";
    document.title =
      "Proper - 5piso: " + ReactHtmlParser(foundProperty.titulo) + " (" + foundProperty.id + ")";
    return (
      <div className="App">
        <h1 id="property-title" data-id={foundProperty.id}>
          {ReactHtmlParser(foundProperty.titulo)}
        </h1>
        {foundProperty.media.fotos.length > 0 && (
          <div className="mainImageContainer">
            <img
              src={foundProperty.media.fotos[0].url}
              alt={foundProperty.titulo}
              className="mainImage"
              width="100%"
              height="100%"
            />
          </div>
        )}
        {detalles && (
          <div>
            <h2>Detalles de la propiedad</h2>
            <table className="detalles">
              <tbody>
                {Object.keys(foundProperty.details).map(function (key, index) {
                  if (foundProperty.details[key] != "") {
                    return (
                      <tr key={key} className="detallesRow">
                        <td className="detalleLabel">
                          {capitalizeFirst(
                            key.replace("_", " ").replace("n-i", "ñ")
                          )}
                        </td>
                        <td>{ReactHtmlParser(foundProperty.details[key])}</td>
                      </tr>
                    );
                  }
                })}
                <tr key="m2terreno" className="detallesRow">
                  <td className="detalleLabel">Terreno</td>
                  <td>{foundProperty.size.area_terreno} m2</td>
                </tr>
                <tr key="m2construccion" className="detallesRow">
                  <td className="detalleLabel">Construido</td>
                  <td>{foundProperty.size.area_construido} m2</td>
                </tr>
              </tbody>
            </table>
            <div
              className="button secondary"
              id="view_photos"
              onClick={() => {
                window.analytics.track("view_photos", {
                  property_id: foundProperty.id,
                  agency:agency,
                  status: photos,
                  position: "top",
                });
                setPhotos(!photos);
              }}
            >
              <h3 className="buttonText secondaryLabel">
                {photos ? "Ocultar fotos" : "Más fotos"}
              </h3>
            </div>
            {photos && (
              <div>
                {foundProperty.media.fotos.map(function (item, index) {
                  if (index > 0) {
                    return (
                      <div
                        key={"Photos_" + index}
                        className="mainImageContainer"
                      >
                        <img
                          src={item.url}
                          alt={foundProperty.titulo + "_" + index}
                          className="mainImage"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    );
                  }
                })}
                <div
                  className="button secondary"
                  id="view_photos"
                  onClick={() => {
                    window.analytics.track("view_photos", {
                      property_id: foundProperty.id,
                      agency:agency,
                      status: photos,
                      position: "bottom",
                    });
                    setPhotos(!photos);
                  }}
                >
                  <h3 className="buttonText secondaryLabel">
                    {photos ? "Ocultar fotos" : "Más fotos"}
                  </h3>
                </div>
              </div>
            )}
          </div>
        )}
        <nav>
          <div
            className="button "
            id="view_details"
            onClick={() => {
              window.analytics.track("view_details", {
                property_id: foundProperty.id,
                agency:agency,
                status: detalles,
              });
              setDetalles(!detalles);
            }}
          >
            <h3 className="buttonText">
              {detalles ? "Ocultar detalles" : "Detalles de la propiedad"}
            </h3>
          </div>
          <div className="button whatsapp">
            <Link
              id="open_whatsapp"
              to={{
                pathname:
                  "https://wa.me/525561780088?text=" +
                  encodeURI(
                    "Hola, quiero pedir información de " +
                    ReactHtmlParser(foundProperty.titulo) +
                      " (" +
                      foundProperty.id +
                      ") que encontré en Proper"
                  ),
              }}
              target="_blank"
              className="buttonText whatsappText"
              onClick={() => {
                window.analytics.track("open_whatsapp", {
                  property_id: foundProperty.id,
                  agency: agency,
                  detallesStatus: detalles,
                  photoStatus: photos,
                });
                setDetalles(!detalles);
              }}
            >
              Contáctanos por Whatsapp
            </Link>
          </div>
          {/* <div className="button">
            <Link to="/about" className="buttonText">
              Propiedades similares
            </Link>
          </div> */}
        </nav>
      </div>
    );
  } else {
    Redirect();
    //return <h1>Will Redirect</h1>;
  }
}

function Redirect() {
  window.location.href = "https://5piso.com/home";
}
function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const codes = [
  { code: "cef44", property: 958, active: true },
  { code: "5ywmt", property: 1185, active: true },
  { code: "o12o2", property: 1062, active: true },
  { code: "xslwk", property: 1199, active: true },
  { code: "vge65", property: 1193, active: true },
  { code: "ow3s9", property: 1184, active: true },
  { code: "escgn", property: 1179, active: true },
  { code: "lboan", property: 1210, active: true },
];
const properties = [
  {
    id: 958,
    titulo: "Departamento en Venta, Olivar de los Padres",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.696609545558!2d-99.24297738470764!3d19.33896704867836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20083f18ae759%3A0x841cf1b13c66b0a5!2sCalz.+Desierto+de+los+Leones+5497%2C+Alcantarilla%2C+01729+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses!2smx!4v1550160027429",
      direccion: "Calzada Desierto de los Leones",
      colonia: "Olivar de los Padres",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `Excelente departamento para estrenar de un conjunto de 20 divididos en 3 Torres.<br />
    Muy buena ubicaci&oacute;n, en el cruce de Av. Desierto de los Leones y Av. Toluca, con una vista maravillosa y arbolada que da al 2&deg; piso de la Superv&iacute;a Poniente. Todo el condominio es nuevo, acabados de lujo, pisos de loseta y m&aacute;rmol, carpinter&iacute;a de primera.<br />
    Cuenta con estancia con balc&oacute;n, cocina integral con estufa, rec&aacute;mara con closet y ba&ntilde;o, con vista a la avenida, rec&aacute;mara que da al interior con closet y un ba&ntilde;o que da servicio a segunda rec&aacute;mara y visitas, cuarto de lavado, escalera de caracol para subir al roof garden de 66.66 m2, bodega privada 9.91 m2, 2 lugares de estacionamiento; el departamento cuenta con canceles de vidrio templado, regaderas, espejos en los ba&ntilde;os y persianas en las ventanas.&nbsp;<br />
    &iexcl;Vale la pena conocerlo, est&aacute; de revista! Cerca de un Centro Comercial de prestigio, restaurantes, escuelas, parques, farmacias y transporte de todo tipo. SI SE ADMITEN MASCOTAS.`,
    precio: {
      renta: "",
      venta: "3950000",
      mantenimiento: "2500",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 2,
      baños: 2,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "5 a&ntilde;os",
    },
    aditional_info:{
      pisos: 3,
      nivel_total: 4,
    },
    size: {
      area_terreno: "83",
      area_construido: "83",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/v_958_1.mp4",
        },
      ],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_958/958_11.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Venta",
  },
  {
    id: 1041,
    titulo: "Departamento en Renta, Portales Sur",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.1485450816704!2d-99.15256502045413!3d19.362719686988072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ffb43688bf15%3A0x2c683827555fde95!2sMonrovia%2C%20Portales%20Sur%2C%2003300%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1591971943414!5m2!1ses-419!2smx",
      direccion: "Monrovia",
      colonia: "Portales Sur",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "rent",
    descripcion: `Aprovecha la gran oportunidad de rentar &eacute;ste lindo y pr&aacute;ctico departamento qu&eacute; te ofrece espacios muy c&oacute;modos con una distribuci&oacute;n muy bi&eacute;n aprovechada, d&oacute;nde encontrar&aacute;s una linda cocina, con tu estancia comedor, tendr&aacute;s 2 rec&aacute;maras, 2 ba&ntilde;os completos, contar&aacute;s con tu &aacute;rea de lavado, un closet de blancos que siempre es necesario y lo mejor, 2 lugares de estacionamiento independientes no te estorbar&aacute;s ni t&uacute; mismo. Te ofrece una excelente ubicaci&oacute;n y poder aprovechar la cercan&iacute;a del gran Parque Xicot&eacute;ncatl qu&eacute; te invita a caminar y pasear a t&uacute; mascota. SE ACEPTAN MASCOTAS, MANTENIMIENTO INCLUIDO.<br />
    Es con p&oacute;liza jur&iacute;dica.`,
    precio: { renta: "14500", venta: "", mantenimiento: "1500", traspaso: "" },
    moneda: "",
    details: {
      recámaras: 2,
      baños: 2,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "No",
      jardin: "",
      antiguedad: "9 a&ntilde;os",
    },
    aditional_info:{
      pisos: 1,
      nivel_total: 3,
    },
    size: {
      area_terreno: "",
      area_construido: "80",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1041/1041_1.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Renta",
  },
  {
    id: 1062,
    titulo: "Departamento en Venta, San Jos&eacute; Insurgentes",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.963672492685!2d-99.18931438492507!3d19.370725586919505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff888c31504b%3A0x5fa76c299d7aef0b!2sLos%20Ju%C3%A1rez%2C%20San%20Jos%C3%A9%20Insurgentes%2C%2003900%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1592948248083!5m2!1ses-419!2smx",
      direccion: "Los Ju&aacute;rez",
      colonia: "San Jos&eacute; Insurgentes",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"Departamento nuevo en venta, amueblado o no. Decorado con estilo mexicano con una linda terraza interior Cuenta con 2 estacionamientos y vigilancia las 24 horas. SE ACEPTAN MASCOTAS. &nbsp;<br />`,
    precio: {
      renta: "",
      venta: "4480000",
      mantenimiento: "1500",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 2,
      baños: 2,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Si",
      jardin: "",
      antiguedad: "1 a&ntilde;o",
    },
    aditional_info:{
      pisos: 3,
      nivel_total: 4,
    },
    size: {
      area_terreno: "73",
      area_construido: "73",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1062/1062_9.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Venta",
  },
  {
    id: 1179,
    titulo: "Oficina en Venta, San Jer&oacute;nimo",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.1613257275653!2d-99.2246811847078!3d19.318804649314636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdff909ba852ff%3A0x91a8d1cfeed957fb!2sSuiza%2C+San+Jer%C3%B3nimo+Aculco%2C+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses!2smx!4v1551480069573",
      direccion: "Cerrada de Suiza",
      colonia: "San Jer&oacute;nimo Aculco",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `Excelentes oficinas en VENTA como nuevas en la Colonias San Jer&oacute;nimo Aculco, junto a perif&eacute;rico y muy cerca de la superv&iacute;a poniente, Hospital &Aacute;ngeles del Pedregal, Centro Comercial ARTZ, etc. Edificio de 6 niveles, cuenta con 5 s&oacute;tanos para estacionamiento, recibidor, CCTV, conmutador, planta el&eacute;ctrica, 2 elevadores. Instalaci&oacute;n para aire acondicionado. Est&eacute; en un 5&ordm; Piso consta de 150 m2, 9 lugares de estacionamiento, bodega de 10 m2. Se entregan con piso de duela, contactos, persianas. La oficina es muy iluminada y se puede acondicionar a sus necesidades. Cuenta con ba&ntilde;o de hombres y ba&ntilde;o de mujeres. En 7&ordm; nivel hay un roof garden com&uacute;n, cuenta con sala de juntas totalmente equipada para 20 personas con medio ba&ntilde;o y barra para caf&eacute;. Adem&aacute;s de un comedor para empleados para 30 personas con mesas, sillas, 2 hornos de microondas, medio ba&ntilde;o. Terraza con mesas muy agradable para tomar un caf&eacute;, comer o fumar al aire libre, hermosa vista. Ideal para personas que est&aacute;n buscando un nuevo sitio para renovarse y formar una empresa con todos los servicios, Con una cita te dar&aacute;s cuenta!!`,
    precio: {
      renta: "",
      venta: "8300000",
      mantenimiento: "6728",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 1,
      baños: 2,
      estacionamientos: 9,
      elevadores: 2,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "6 a&ntilde;os",
    },
    aditional_info:{
      pisos: 5,
      nivel_total: 6,
    },
    size: {
      area_terreno: "150",
      area_construido: "150",
    },
    uso_suelo: "Oficina",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_11.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_12.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_13.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1179/1179_14.jpg",
        },
      ],
    },
    metadescripcion: "Oficina en Venta, San Jer&oacute;nimo",
  },
  {
    id: 1184,
    titulo: "Casas en Condominio Horizontal en Venta, Lomas de Padierna",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.7560961709146!2d-99.22980908461946!3d19.29297008696384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdffac8cde204d%3A0xcf538eb181ba1e4d!2sTulum%2C%20Lomas%20de%20Padierna%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1631901941425!5m2!1ses!2smx",
      direccion: "Tulum",
      colonia: "Lomas de Padierna",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"&iexcl;Lindo conjunto de solamente 5 casas muy seguras! Abajo est&aacute; el cuarto muy amplio de servicio con ba&ntilde;o, tambi&eacute;n esta el cuarto de lavander&iacute;a muy amplio otro espacio que puede ser home office&rdquo; y una bodega muy grande.<br />
    Subiendo esta sala, comedor con una gran terraza, cocina con despensa y medio ba&ntilde;o.<br />
    La rec&aacute;mara principal con vestidor, ba&ntilde;o y un balc&oacute;n, las otras 2 rec&aacute;maras con closets y comparten un ba&ntilde;o y tiene un closet de blancos. Arriba est&aacute; un roof garden con asador muy agradables, tiene muy buenos espacios, altura de muros y est&aacute; muy iluminada.<br />
    El garage es para 2 autos cubiertos y la calle es tranquila, cuenta con caseta de vigilancia propia, todas tienen jard&iacute;n y est&aacute;n muy bien pensadas; &iexcl;Tienen todo!"`,
    precio: { renta: "", venta: "5888000", mantenimiento: "", traspaso: "" },
    moneda: "",
    details: {
      recámaras: 3,
      baños: 2,
      estacionamientos: 3,
      elevadores: 0,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "Nueva",
    },
    aditional_info:{
      pisos: 0,
      nivel_total: 3,
    },
    size: {
      area_terreno: "70",
      area_construido: "184",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1184/1184_10.jpg",
        },
      ],
    },
    metadescripcion:
      "Casas en Condominio Horizontal en Venta, Lomas de Padierna",
  },
  {
    id: 1185,
    titulo: "Departamento en Venta, Los Alpes",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.214161619125!2d-99.19755109999998!3d19.359877400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d200226535df1b%3A0xb824bd5fb379c738!2sCalz%20de%20los%20Leones%2C%20Los%20Alpes%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1631931767471!5m2!1ses-419!2smx",
      direccion: "Calzada de los Leones",
      colonia: "Los Alpes",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"Departamento muy amplio con mucha luz, remodelado, muy bien ubicado a media cuadra de barranca de Muerto, con dos lugares que no se estorban y muy privado. Cuenta con 3 rec&aacute;maras, la principal con ba&ntilde;o y vestidor, las otras dos con closet.<br />
    <br />
    Cuenta con cuarto de tv muy amplio y cuarto de servicio integrado, sala y comedor amplios.<br />
    <br />
    Excelente ubicaci&oacute;n."`,
    precio: {
      renta: "",
      venta: "6990000",
      mantenimiento: "3700",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 3,
      baños: 2.5,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "35 a&ntilde;os",
    },
    aditional_info:{
      pisos: 4,
      nivel_total: 12,
    },
    size: {
      area_terreno: "206",
      area_construido: "206",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1185/1185_10.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Venta, Los Alpes",
  },
  {
    id: 1190,
    titulo: "Departamento en Renta en Las &Aacute;guilas",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.046538434248!2d-99.25504178461574!3d19.58250678679942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21c431f273e85%3A0x86034e564b8536da!2sAv.%20de%20Las%20%C3%81guilas%2C%20Las%20Aguilas%2C%2052949%20Cd%20L%C3%B3pez%20Mateos%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1632848983250!5m2!1ses!2smx",
      direccion: "Calzada de Las &Aacute;guilas",
      colonia: "Las &Aacute;guilas",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"Excelente departamento en renta en una ubicaci&oacute;n inmejorable, se localiza en la parte baja de Las &Aacute;guilas, a unos metros de la v&iacute;a r&aacute;pida Alfonso Caso Andrade, a 10 minutos de la superv&iacute;a Poniente y muy cerca de Perif&eacute;rico Sur; as&iacute; como de restaurantes, plazas comerciales, iglesias, parques, etc. &nbsp;<br />
    <br />
    Departamento exterior en 2o nivel, muy iluminado y con magn&iacute;fica distribuci&oacute;n, cuenta con sala, comedor, cocina integral abierta con barra, cuarto de lavado, tres rec&aacute;maras con closet, la principal con ba&ntilde;o, las otras dos rec&aacute;maras comparten ba&ntilde;o con el &aacute;rea social, una de ellas cuenta con un agradable balc&oacute;n; dos amplios estacionamientos techados que no se estorban con vecinos. Cuenta con persianas, cristal templado en ba&ntilde;os y pisos de porcelanato. &nbsp;<br />
    <br />
    Edificio de 2 torres de 3 niveles, 15 departamentos en total, cuenta con elevador, estacionamiento para visitas y estricta vigilancia las 24 horas. &nbsp;<br />
    <br />
    MANTENIMIENTO INCLUIDO. SE ACEPTAN MASCOTAS. &nbsp;"`,
    precio: { renta: "20200", venta: "", mantenimiento: "3000", traspaso: "" },
    moneda: "",
    details: {
      recámaras: 3,
      baños: 2,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "11 a&ntilde;os",
    },
    aditional_info:{
      pisos: 4,
      nivel_total: 3,
    },
    size: {
      area_terreno: "100",
      area_construido: "100",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_11.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1190/1190_12.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Renta en Las &Aacute;guilas",
  },
  {
    id: 1193,
    titulo:
      "Casa en Condominio Horizontal en Venta, San Jer&oacute;nimo L&iacute;dicie",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.017786123686!2d-99.22577068461894!3d19.32503448694547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdff9b7212693b%3A0xea452a8af158aa92!2sAv.%20San%20Jer%C3%B3nimo%2C%20San%20Jer%C3%B3nimo%20L%C3%ADdice%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1633015807488!5m2!1ses!2smx",
      direccion: "Avenida San Jer&oacute;nimo",
      colonia: "San Jer&oacute;nimo L&iacute;dice",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"Hermosa casa en Condominio Horizontal de s&oacute;lo 11 casas muy seguras con vigilancia las 24 horas.<br />
    &iexcl;Tiene un hermoso jard&iacute;n con terraza!<br />
    La sala con chimenea y bar, un comedor con puertas corredizas que se comunica a la terraza y jard&iacute;n (para las fiestas), cocina y antecomedor y medio ba&ntilde;o de visitas.<br />
    La cocina tiene una gran despensa junto est&aacute; el cuarto de lavado.<br />
    Tiene 4 rec&aacute;maras con closets y cada una con ba&ntilde;o, cuarto muy grande de estudio &oacute; puede ser cuarto de TV, una peque&ntilde;a biblioteca muy acogedora que es cuarto de estar y tv.<br />
    Arriba est&aacute; el cuarto de servicio con ba&ntilde;o, un peque&ntilde;o gimnasio.<br />
    Tambi&eacute;n se encuentra una gran bodega y el estacionamiento es para 4 coches techados.<br />
    Es una casa muy completa que est&aacute; a una cuadra de la Escuela Superior de Guerra en San Jer&oacute;nimo L&iacute;dice en una calle empedrada muy bonita y tranquila."`,
    precio: {
      renta: "",
      venta: "11800000",
      mantenimiento: "3000",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 4,
      baños: 4.5,
      estacionamientos: 4,
      elevadores: 0,
      vigilancia: "Propia",
      jardin: "250m2",
      antiguedad: "23 a&ntilde;os",
    },
    aditional_info:{
      pisos: 0,
      nivel_total: 2,
    },
    size: {
      area_terreno: "376",
      area_construido: "360",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_11.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_12.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_13.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_14.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_15.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_16.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1193/1193_17.jpg",
        },
      ],
    },
    metadescripcion:
      "Casa en Condominio Horizontal en Venta, San Jer&oacute;nimo L&iacute;dicie",
  },
  {
    id: 1199,
    titulo: "Departamento en Venta, Tlacoquemecatl",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.871031914581!2d-99.17580298461839!3d19.374736186917282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff9110287565%3A0xfb1cbd2d08cde201!2sMoras%2C%20Tlacoquemecatl%20del%20Valle%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1634769868150!5m2!1ses!2smx",
      direccion: "Moras",
      colonia: "Tlacoquem&eacute;catl",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "sale",
    descripcion: `"Disfruta de la iluminaci&oacute;n y seguridad que te brinda este precioso departamento, as&iacute; como su excelente ubicaci&oacute;n cerca de avenidas principales como son Insurgentes Sur y F&eacute;lix Cuevas, a un lado de la colonia Del Valle. Todo lo encontrar&aacute;s a unas cuadras: restaurantes, plazas comerciales, parques, colegios, etc.<br />
    <br />
    Cuenta con amplia sala y comedor, cocina integral cerrada con cubierta de granito, &aacute;rea de lavado, 2 rec&aacute;maras con ba&ntilde;o completo, la principal con vestidor, ba&ntilde;o para visitas y 2 lugares de estacionamiento en bater&iacute;a que no se estorban con vecinos. Cuenta con acabados de lujo, amplias ventanas con persianas nuevas y techos con altura de m&aacute;s de 3 metros.<br />
    <br />
    Condominio de dos torres de 4 niveles, cuenta con enorme jard&iacute;n con juegos infantiles y bancas, sal&oacute;n de eventos con cocina y ba&ntilde;os, gimnasio con regaderas y vapor, ba&ntilde;os de servicio y vigilancia las 24 horas. &iexcl;VEN A CONOCERLO, TE ENCANTAR&Aacute;!"`,
    precio: {
      renta: "",
      venta: "6990000",
      mantenimiento: "2200",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 2,
      baños: 2.5,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Privada",
      jardin: "",
      antiguedad: "10 a&ntilde;os",
    },
    aditional_info:{
      pisos: 2,
      nivel_total: 4,
    },
    size: {
      area_terreno: "126",
      area_construido: "126",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_11.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Venta, Tlacoquemecatl",
  },
  {
    id: 1202,
    titulo: "Departamento en Renta, Las &Aacute;guilas",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.0464204740647!2d-99.25504178482436!3d19.582511840943525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21c431f273e85%3A0x86034e564b8536da!2sAv.%20de%20Las%20%C3%81guilas%2C%20Las%20Aguilas%2C%2052949%20Cd%20L%C3%B3pez%20Mateos%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1634856092068!5m2!1ses!2smx",
      direccion: "Calzada de las &Aacute;guilas",
      colonia: "Las &Aacute;guilas",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Departamento",
    transaction: "rent",
    descripcion: `Excelente departamento interior con una privacidad asegurada, estilo y funcionalidad cuenta con 2 rec&aacute;maras, 2 ba&ntilde;os completos, estancia, cocina abierta con barra, &aacute;rea de lavado integrada, sala T.V. o posibilidad de que sea la 3&ordf;. Rec&aacute;mara con closet, 2 estacionamientos fijos, bodega, finos acabados, muy iluminado solo son 18 departamentos, 3 torres con 6 departamentos cada uno, &eacute;ste departamento se encuentra en 1er. Nivel, cuenta con un bello patio central que te encantar&aacute;. Mejora tu estilo de vida estando ah&iacute;. Est&aacute;n muy bien dise&ntilde;ados. Una visita obligada si estas buscando un lugar para rentar. SE ADMITEN MASCOTAS haci&eacute;ndose responsables de ella. EL PRECIO INCLUYE MANTENIMIENTO. Agenda hoy.`,
    precio: {
      renta: "18500",
      venta: "",
      mantenimiento: "0",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 2,
      baños: 2,
      estacionamientos: 2,
      elevadores: 1,
      vigilancia: "Propia",
      jardin: "",
      antiguedad: "11 a&ntilde;os",
    },
    aditional_info:{
      pisos: 1,
      nivel_total: 0,
    },
    size: {
      area_terreno: "92",
      area_construido: "92",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_6.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_7.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_8.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_9.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_10.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1199_11.jpg",
        },
      ],
    },
    metadescripcion: "Departamento en Renta, Las &Aacute;guilas",
  },
  {
    id: 1210,
    titulo: "Oficinas en Renta en La Roma",
    location: {
      maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.0647635101723!2d-99.15707968468375!3d19.40960754644414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff0fbf5783c7%3A0xe3ca100e50b15df0!2sAv.%20Cuauht%C3%A9moc%2C%20C.%20U.%20Benito%20Ju%C3%A1rez%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1636733610484!5m2!1ses!2smx",
      direccion: "Cuauht&eacute;moc",
      colonia: "Cuauht&eacute;moc",
      city: "Mexico City",
      state: "CDMX",
      country: "MX",
    },
    propertyType: "Oficina",
    transaction: "rent",
    descripcion: `"Se renta oficina con uso de suelo para comercio, servicios en el coraz&oacute;n de la Colonia Roma a una cuadra de los tribunales, ideal para abogados, arquitectos, o cualquier tipo de oficina o servicio, cuenta con 2 ba&ntilde;os propios con una ubicaci&oacute;n excelente y segura estilo colonial.<br />
    Hay una pensi&oacute;n a la vuelta, MANTENIMIENTO INCLUIDO.<br />
    PRECIO MAS IVA."`,
    precio: {
      renta: "29500",
      venta: "",
      mantenimiento: "0",
      traspaso: "",
    },
    moneda: "",
    details: {
      recámaras: 5,
      baños: 2,
      estacionamientos: 0,
      elevadores: 0,
      vigilancia: "No",
      jardin: "",
      antiguedad: "40 a&ntilde;os",
    },
    aditional_info:{
      pisos: 1,
      nivel_total: 1,
    },
    size: {
      area_terreno: "112",
      area_construido: "112",
    },
    uso_suelo: "Habitacional",
    fecha_ocupacion: "",
    media: {
      video: [],
      fotos: [
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_1.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_2.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_3.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_4.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_5.jpg",
        },
        {
          url: "https://proper-start.s3.us-east-2.amazonaws.com/properties/5piso_1199/1210_6.jpg",
        },
      ],
    },
    metadescripcion: "Oficinas en Renta en La Roma",
  },
];

export default App;
